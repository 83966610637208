<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Type <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.type"
                  :items="listType"
                  :rules="[v => !!v || 'Type is required']"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Month <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.month"
                  :items="listMonth"
                  :rules="[v => !!v || 'Month is required']"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Year <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.year"
                  :items="listYear"
                  :rules="[v => !!v || 'Year is required']"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row class="py-0">
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Amount <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  type="number"
                  v-model.number="form.amount"
                  persistent-hint
                  :hint="formatPrice(form.amount)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1">
        <v-row class="py-0">
          <v-col cols="12" class="mb-4 py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="submit">
                  <v-icon large>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "create-income-statement",
  data: () => ({
    defaultForm: {
      amount: 0,
      type: "TYPE_1",
      month: 1,
      year: new Date().getFullYear(),
    },
    valid: true,
    menuMonthDate: false,
    menuYearDate: false,
    form: {},
    listMonth: [
      {
        text: "Januari",
        value: 1,
      },
      {
        text: "Februari",
        value: 2,
      },
      {
        text: "Maret",
        value: 3,
      },
      {
        text: "April",
        value: 4,
      },
      {
        text: "Mei",
        value: 5,
      },
      {
        text: "Juni",
        value: 6,
      },
      {
        text: "Juli",
        value: 7,
      },
      {
        text: "Agustus",
        value: 8,
      },
      {
        text: "September",
        value: 9,
      },
      {
        text: "Oktober",
        value: 10,
      },
      {
        text: "November",
        value: 11,
      },
      {
        text: "Desember",
        value: 12,
      },
    ],
  }),

  computed: {
    ...mapState("incomeStatement", ["listType"]),
    listYear() {
      const year = new Date().getFullYear();
      let array = [];
      for (let i = 0; i <= 5; i++) {
        array.push(year + i);
      }
      for (let i = 1; i <= 5; i++) {
        array.unshift(year - i);
      }
      return array;
    },
  },

  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.$store.commit("SET_LOADER", true);
        await this.$store
          .dispatch("incomeStatement/create", this.form)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("incomeStatement/getType")
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },

  mounted() {
    this.getInitialData();
    this.form = Object.assign({}, this.defaultForm);
  },
};
</script>

<style></style>
